<template>
    <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
        <ListingComponent ref="UsersList"
                          class="bg-white"
                          :addNewButton="addNewButton"
                          :requestProvider="requestProvider"
                          :columnConfig="columnConfig">
        </ListingComponent>
        <b-modal v-model="isDeleteModal"
                 :no-close-on-backdrop="true"
                 centered title="Delete modal"
                 @cancel="closeDeleteModal"
                 @ok="removeItem">
            <form>
                <h3 class="text-black-50">Do you really want to delete</h3>
            </form>
        </b-modal>
    </div>
</template>

<script>
    import ListingComponent from '@/components/listing/ListingComponent'

    export default {
        name: "UsersListPage",
        data () {
            return {
              defultImage: require('../../assets/img/user-default.png'),
              id: null,
              isDeleteModal: false,
              columnConfig: [
                  {
                    columnCode: 'action',
                    columnName: 'Action',
                    value: (item) => {
                      return (
                          <b-dropdown variant="link" class="listing-action">
                          <b-dropdown-item onClick={() => this.showDeleteModal(item)}>Delete</b-dropdown-item>
                      </b-dropdown>
                    )
                    }
                  },
                  {
                    columnCode: 'IMAGE',
                    columnName: 'Image',
                    align: 'left',
                    customizeColumn: true,
                    value: item => {
                      if (!item.image)
                      return <img className="profile-img-sm rounded-circle mb-2" style="height: 40px;" src={this.defultImage}/>
                      return <img className="profile-img-sm rounded-circle mb-2" style="height: 40px;" src={item.image.imageURL}/>
                    }
                  },
                  {
                        columnCode: 'USER_NAME',
                        columnName: 'User Name',
                        align: 'left',
                        value: item => {
                            return item.userName
                        }
                    },
                    {
                        columnCode: 'EMAIL',
                        columnName: 'Email',
                        align: 'left',
                        value: item => {
                            return item.email
                        }
                    },
                    {
                        columnCode: 'PHONE',
                        columnName: 'Phone',
                        align: 'left',
                        value: item => {
                            return item.phone
                        }
                    },
                     {
                        columnCode: 'ROLE',
                        columnName: 'Role',
                        align: 'left',
                        value: item => {
                            return item.roleCode
                        }
                    }
                ]
            }
        },
        methods: {
            requestProvider (payload) {
                return this.axios.get('member/list', payload)
            },
          addNewButton () {
            return (
                <router-link class="btn btn-success mr-3" to="#"><i class="fas fa-plus-circle"></i></router-link>


          )
          },
          closeDeleteModal () {
                this.id = null
                this.$nextTick(() => {
                    this.isDeleteModal = false
                })
            },
            showDeleteModal (item) {
                if (!item.id) {
                    this.$toastr.error('Users not found')
                }
                this.id = item.id
                this.isDeleteModal = true
            },
            removeItem () {
                if (!this.id) {
                    return
                }
                this.axios.delete('member/item/', {params: {id: this.id}})
                    .then((result) => {
                        this.$refs.UsersList.doRequest()
                        if (result.data && result.data.status === 'success') {
                            this.$toastr.success('User successfully deleted')
                        }
                        this.isDeleteModal = false
                    })
            }
        },
        components: {
            ListingComponent
        }
    }
</script>

<style scoped>

</style>